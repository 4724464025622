import { BasicState } from "./basicstate.class";

export const MAIN_STATES = {
    UNCATEGORIZED: 1,
    CATEGORY_INVITATION: 2,
    MAIN: 3,
    BUSINESSDETAIL: 4,
    MANUAL_ENTRY: 5,
    GO_BACK_MAIN: 6,
    DELETE_MANUAL_ENTRY: 7,
    GO_BACK_MAIN_REFRESH: 8,
    TRIAL_EXPIRED: 9
    
}


export class MainState extends BasicState {
    constructor(appstore) {
        super(
            'main',
            appstore
        );
        this.nextState = 0;
        this.replaceBackAction = null;
        appstore.pushHistoryState(this.createBackAction(), '/');
    }

    async performAction(action, data = null) {
        switch (action) {
            case MAIN_STATES.UNCATEGORIZED:
                let uncategorizedgastos;
                if(!(data)){
                    uncategorizedgastos = await this.Store.Service('Gastos').getUncategorizedGastos();
                } else {
                    uncategorizedgastos = data;
                    data = null;
                }
                if(uncategorizedgastos.length > 0) {
                    let categories = await this.Store.Service('Category').getCategories();
                    this.Store.View('uncategorized').loadUncategorizedGastos(uncategorizedgastos, categories);
                    this.nextState = MAIN_STATES.MAIN;
                    break;
                }
            case MAIN_STATES.CATEGORY_INVITATION:
                let categoryinvitations;
                if(!(data)) {
                    categoryinvitations = await this.Store.Service('Category').getPendingCategoryInvitations();
                } else {
                    categoryinvitations = data;
                    data = null;
                }
                if(categoryinvitations.length > 0) {
                    this.Store.View('categorypendinginvitations').load(categoryinvitations);
                    this.nextState = MAIN_STATES.MAIN;
                    break;
                }

            case MAIN_STATES.MAIN:
                return import(/* webpackChunkName: "moment" */  'moment/src/moment')
                .then(({ default: moment }) => {
                    let currentDate = moment();
                    this.Store.View('main').loadUIDates(currentDate.month(), currentDate.year());
                });
                break;
            case MAIN_STATES.MANUAL_ENTRY:
                let categories = await this.Store.Service('Category').getCategories();
                this.Store.View('manualentry').load(categories);
                break;
            case MAIN_STATES.BUSINESSDETAIL:
                this.Store.pushHistoryState(this.createBackAction(MAIN_STATES.GO_BACK_MAIN), 'businessdetail');
                this.Store.View('detailed').loadDetailedView(data.dateText, data.businessinfo);
                break;
            case MAIN_STATES.DELETE_MANUAL_ENTRY:
                this.replaceBackAction = this.createBackAction(MAIN_STATES.GO_BACK_MAIN_REFRESH);
                // this.Store.View('detailed').loadDetailedView(data.dateText, data.businessinfo);
                break;
            case MAIN_STATES.TRIAL_EXPIRED:
                // The trial expired
                this.Store.View('expiredsubscription').load(data);
                break;
        }
    }

    async backAction(backAction, parameters = null) {
        if(this.replaceBackAction) {
            backAction = this.replaceBackAction.backAction;
            parameters = this.replaceBackAction.data;
            this.replaceBackAction = null;
        }
        switch (backAction) {
            case MAIN_STATES.GO_BACK_MAIN:
                console.log('MAIN_STATES.GO_BACK_MAIN');
                this.Store.View('main').loadMain();
                break;
            case MAIN_STATES.GO_BACK_MAIN_REFRESH:
                console.log('MAIN_STATES.GO_BACK_MAIN_REFRESH');
                this.Store.View('main').refreshData();
                break;   
        }
    }

    goToNextState() {
    }
}