import { BasicView } from "../basicview.class";
import { MDCTextField } from '@material/textfield';
import { MDCCheckbox } from '@material/checkbox';
import {MDCRipple} from '@material/ripple';
import { INITIAL_STATES } from "../../store/states/initial.state";


export class LoginModule extends BasicView {

    constructor() {
        super(
            'login',
            
        );
        this.dom = document.querySelector('.loginview');
    }

    init() {  
        this.signUpSection = this.dom.querySelector('.new-user-section');
        this.loginSection = this.dom.querySelector('.login-section');
        this.allSections = [this.signUpSection, this.loginSection];
        this.registryFields= {
            fullname: new MDCTextField(this.dom.querySelector('.namefield')),
            username: new MDCTextField(this.dom.querySelector('.signupuserfield')),
            email: new MDCTextField(this.dom.querySelector('.signupemailfield')),
            password: new MDCTextField(this.dom.querySelector('.signuppasswordfield')),
            terms: new MDCCheckbox(this.dom.querySelector('.mdc-checkbox')),
            signupaction: new MDCRipple(this.dom.querySelector('.signup-action'))
        }
        this.loginFields = {
            emailField: new MDCTextField(this.dom.querySelector('.emailfield')),
            passwordField: new MDCTextField(this.dom.querySelector('.passwordfield'))
        }
        let buttonRipple = new MDCRipple(this.dom.querySelector('.login-action'));
        (function (that) {
            that.dom.querySelector('.login-action').addEventListener('click', function (){
                that.Service('User').signInUser(that.loginFields.emailField.value, that.loginFields.passwordField.value)
                .then(result => {
                    that.checkforlogin(result);
                });
            }, false);
            that.dom.querySelector('.cancel-signup-action').addEventListener('click', function (){
                that.Store.backHistoryState();
            }, false);
            that.dom.querySelector('.cancel-login-action').addEventListener('click', function (){
                that.Store.backHistoryState();
            }, false);
            that.dom.querySelector('.usernamefield').addEventListener('blur', function () {
                console.log('test blur');
                that.Service('User').checkUserName(that.registryFields.username.value)
                .then(istaken => {
                    that.registryFields.username.valid = !istaken;
                    // that.registryFields.username.shakeLabel(istaken);
                });
            }, false);
            that.dom.querySelector('.emailsignupfield').addEventListener('blur', function () {
                console.log('test blur');
                that.Service('User').checkEmail (that.registryFields.email.value)
                .then(istaken => {
                    that.registryFields.email.valid = !istaken;
                    // that.registryFields.email.shakeLabel(istaken);
                });
            }, false);
            that.dom.querySelector('.signup-action').addEventListener('click', function (){
                let termsapproved = that.registryFields.terms.checked;
                if(termsapproved) {
                    // Create the object
                    let nameTokens = that.registryFields.fullname.value.split(' ');
                    let newuser = {
                        name: '',
                        surname: '',
                        fullname: that.registryFields.fullname.value,
                        userHash: that.registryFields.username.value,
                        email: that.registryFields.email.value,
                        password: that.registryFields.password.value
                    };
                    switch (nameTokens.length) {
                        case 2:
                            newuser.name = nameTokens[0];
                            newuser.surname = nameTokens[1];
                            break;
                        case 3:
                            newuser.name = nameTokens[0];
                            newuser.surname = nameTokens[1] + ' ' + nameTokens[2];
                            break;
                        case 4:
                            newuser.name = nameTokens[0] + ' ' + nameTokens[1];
                            newuser.surname = nameTokens[2]+ ' '  + nameTokens[3];
                            break;
                    }
                    if(newuser.fullname.length > 0 &&
                        newuser.fullname.length > 0 &&
                        newuser.userHash.length > 0 &&
                        newuser.email.length > 0 &&
                        newuser.password.length > 0) {
                            // Let's create the service
                            that.Service('User').createNewUser(newuser)
                            .then(createdUser => {
                                that.Store.setLoggedUser(createdUser);
                                that.Store.currentState.performAction(INITIAL_STATES.ONBOARDING, createdUser);
                            });
                    }
                }
                
                
                
            },false);
        })(this);
    }

    getGraph() {
        return {left: ['landing'], current: this.getViewObject(), right: ['categoryonboarding', 'main', 'uncategorized',  'expiredsubscription', 'categorypendinginvitations']};
    }

    checkforlogin(userdata) {
        if(userdata) {
            this.Store.setLoggedUser(userdata);
            this.Store.currentState.performAction(INITIAL_STATES.USER_LOGGED, userdata);
        } else {
            this.dom.querySelector('.erorr-inlogin').removeAttribute('hidden');
        }   
    }

    cleanForms() {
        this.loginFields.emailField.value = '';
        this.loginFields.passwordField.value = '';
        this.registryFields.email.value = '';
        this.registryFields.fullname.value = '';
        this.registryFields.password.value = '';
        this.registryFields.username.value = '';
        this.dom.querySelector('.erorr-inlogin').setAttribute('hidden', true);
    }
    performLogin(data) {
        if(data.cleanField) {
            this.cleanForms();
        }
        if(data.isSignUp) {
            if(data.categoryshared) {
                this.dom.querySelector('.login-heading-data').textContent = 'Comenzá a registrar gastos con ' + data.categoryshared.owner + ' en ' + data.categoryshared.name;
            } else {
                this.dom.querySelector('.login-heading-data').textContent = 'Dá el primer paso para mejorar tu salud financiera';
            }
            this.showComponent(this.dom, this.signUpSection);
        } else {
            this.showComponent(this.dom, this.loginSection);
        }
        this.UIManager.viewTransition('login');
    }
}