export class BasicView {

    constructor(viewid, viewresource, iconsresource) {
        this.id = viewid;
        this.viewResource = viewresource;
        this.iconsResource = iconsresource;
        this.dom = null;
        this.UIManager = null;
        this.Services = null;
        this.Store = null;
        this.categoriesDOMs = null;
        this.isSideView = false;
        this.loadingSections = {content:null, loading: null};
        this.moduleFollowsLoadingStandard = false;
        this.isAvailable = true;
    }

    onDocumentLoad(viewcontainer, iconcontainer) {
        if(!this.dom) {
            this.dom = this.createDOM(this.viewResource);
            viewcontainer.appendChild(this.dom);
            if(this.iconsResource && this.iconsResource.length > 0) {
                let iconsource = this.createDOM(this.iconsResource);
                iconsource.querySelectorAll('symbol').forEach(iconelement => {
                    iconcontainer.appendChild(iconelement);    
                });
            } 
        }
        if(this.dom && this.dom.nodeType != Node.TEXT_NODE) {
            this.loadingSections.content = this.dom.querySelector('section.content');
            this.loadingSections.loading = this.dom.querySelector('section.loading');
            if(this.loadingSections.content && this.loadingSections.loading) {
                this.moduleFollowsLoadingStandard = true;
            }
        } else {
            this.dom = null;
            this.isAvailable = false;
        }
        
    }
    
    setStore(store) {
        this.Store = store;
    }

    setViewLoading(value){
        if(this.moduleFollowsLoadingStandard){
            // Set the view in loading state
            if(value){
                this.loadingSections.content.setAttribute('hidden', true);
                this.loadingSections.loading.removeAttribute('hidden');
            } else {
                this.loadingSections.loading.setAttribute('hidden', true);
                this.loadingSections.content.removeAttribute('hidden');
            }
        }
    }

    setServices(services) {
        this.Services = services;
    }

    setUIManager(uimanager) {
        this.UIManager =  uimanager;
        if (this.isSideView) {
            this.UIManager.setSideView(this.getGraph());
        }
    }
    
    cleanContainer(container) {
        while(container.hasChildNodes()){
            container.removeChild(container.firstChild);
        }
    }

    getGraph() {
        console.log(this.id + 'getGraph not overrided in basicview');
    }

    init() {
        console.log(this.id + 'init not not overrided in basicview');
    }

    showSections(container, component) {
        container.forEach(child => {
            child.setAttribute('hidden', true);
        });
        component.removeAttribute('hidden');
    }

    showComponent(container, component) {
        container.childNodes.forEach(child => {
            if(child.nodeType === 1){
                child.setAttribute('hidden', true);
            }
        });
        component.removeAttribute('hidden');
    }

    getViewObject() {
        return {
            id: this.id,
            dom: this.dom
        }
    }

    Service(servicename){
        return this.Services[servicename];
    }

    createDOM(html) {
        var template = document.createElement('template');
        template.innerHTML = html;
        return template.content.firstChild;
    }

    initDrawers() {
        // Get the containing elements
        this.categoriesDOMs = this.dom.querySelectorAll(".category");
        this.categoriesDOMs = [...this.dom.querySelectorAll('.dategrouping') , ...this.categoriesDOMs ];
        this.setHeights();
        this.wireUpTriggers();
        window.addEventListener("resize", this.setHeights);
    }

    setHeights() {
        if(this.categoriesDOMs){
            this.categoriesDOMs.forEach(container => {
                // Get content
                let content = container.querySelector(".content");
                content.removeAttribute("aria-hidden");
                // Height of content to show/hide
                let heightOfContent = content.getBoundingClientRect().height;
                // Set a CSS custom property with the height of content
                container.style.setProperty("--containerHeight", `${heightOfContent}px`);
                // Once height is read and set
                setTimeout(e => {
                    container.classList.add("height-is-set");
                    content.setAttribute("aria-hidden", "true");
                }, 0);
            });
        }
    }

    formatNumber(num) {
        if(num){
            if(!(Object.prototype.toString.call(num) === "[object String]")){
                num = num.toString();
            }
            let formatted = num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
            if(formatted.indexOf('.') < 0) {
                formatted+= '.00';
            }
            return formatted;
        } else {
            "&&";
        }
        
    }

    wireUpTriggers() {
        this.categoriesDOMs.forEach(pcontainer => {
            // Get each trigger element
            let button = pcontainer.querySelector(".header");
            // Get content
            let cont = pcontainer.querySelector(".content");
            (function (btn, container, content) {
                btn.addEventListener("click", () => {
                    btn.setAttribute("aria-expanded", btn.getAttribute("aria-expanded") === "false" ? "true" : "false");
                    container.setAttribute(
                        "data-drawer-showing",
                        container.getAttribute("data-drawer-showing") === "true" ? "false" : "true"
                    );
                    content.setAttribute(
                        "aria-hidden",
                        content.getAttribute("aria-hidden") === "true" ? "false" : "true"
                    );
                });
            })(button, pcontainer, cont)
        });
    }

    setUserToView(user) {

    }
}
