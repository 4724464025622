import { ServiceConnection } from './../services/serviceconnection.class';
import { BlogService } from './../services/blog.service';
import { CategoryService } from './../services/category.service';
import { GastosService } from './../services/gastos.service';
import { UserService } from './../services/user.service';
import { DrawerModule } from '../modules/drawer.module';
import { ViewModule } from '../modules/view.module';
import { InitialState, INITIAL_STATES } from './states/initial.state';
import { MainState, MAIN_STATES } from './states/main.state';
import { PushState } from './states/push.state';
import { BottomBarComponent } from '../components/bottombar.component';
import { PUSH_STATES } from './states/push.state';

const ICON_CATEGORY_SET = {
    name: 'categories',
    icons: {
        'groceries': {id: 'groceries', svg: 'icon-super', label: 'Supermercado'},
        'home': {id: 'home', svg: 'icon-house', label: 'Hogar'},
        'transportation': {id:'transportation', svg: 'icon-transporte', label: 'Transporte'},
        'car': {id:'car', svg: 'icon-auto', label: 'Carro'},
        'category': {id:'category', svg: 'icon-categorize', label: 'Categoria'},
        'gas': {id:'gas', svg: 'icon-gas', label: 'Gasolina'},
        'coffee': {id:'coffee', svg: 'icon-coffee', label: 'Cafe'},
        'entertainment': {id:'entertainment', svg: 'icon-entertainment', label: 'Entretenimiento'},
        'travel': {id:'travel', svg: 'icon-travel', label: 'Viaje'},
    }
}

export class AppStore {

    constructor() {
        this.services = {};
        this.views = {};
        this.APIConnection = null;
        this.UIManager = null;
        this.currentState = null;
        this.savedState = null;
        this.savedView = null;
        this.loggedUser = null;
        this.components = null;
        this.drawer = new DrawerModule();
        this.drawer.init();
        this.iconSets = {}
        this.iconByID = {};
        this.moduleMapping = {};
        this.onDemandModules = {};
        this.historyIgnoreFlag = false;
        this.bottomBar = null;
        this.cleanHistoryState = {cleaning: false, stateId: ''};
        this.modDev = (moddev ? moddev: false);
        this.scrollModules = [];
    }

    initServices() {
        this.bottomBar = new BottomBarComponent(this);
        this.UIManager = new ViewModule (this);
        this.setupOnDemandModules()
        this.APIConnection = (!this.modDev?new ServiceConnection('https://gastos.email/'):new ServiceConnection('http://localhost:8080/'));        
        this.services['Blog'] = new BlogService(this.APIConnection);
        this.services['Category'] = new CategoryService(this.APIConnection);
        this.services['Gastos'] = new GastosService(this.APIConnection);
        this.services['User'] = new UserService(this.APIConnection);
        this.drawer.setStorage(this);
    }

    setupOnDemandModules() {
        this.onDemandModules = {
            'newcategory': function (){return import(/* webpackChunkName: "newcategory" */ './../modules/newcategory/newcategory.module').then(({ NewCategoryModule }) => {
                return new NewCategoryModule(); })},
            'manualentry': function (){return import(/* webpackChunkName: "manualentry" */ './../modules/manualentry/manualentry.module').then(({ ManualEntryModule }) => {
                return new ManualEntryModule(); })},
            'categorydetail': function (){return import(/* webpackChunkName: "categorydetail" */ './../modules/categorydetail/categorydetail.module').then(({ CategoryDetailModule }) => {
                return new CategoryDetailModule(); })},
            'categoryedition': function (){return import(/* webpackChunkName: "categoryedition" */ './../modules/categoryedition/categoryedition.module').then(({ CategoryEditionModule }) => {
                return new CategoryEditionModule(); })},
            'categorybusinessmove': function (){return import(/* webpackChunkName: "categorybusinessmove" */ './../modules/categorybusinessmove/categorybusinessmove.module').then(({ CategoryBusinessMoveModule }) => {
                return new CategoryBusinessMoveModule(); })},
            'categorybudgeting': function (){return import(/* webpackChunkName: "categorybudgeting" */ './../modules/categorybudgeting/categorybudgeting.module').then(({ CategoryBudgetingModule }) => {
                return new CategoryBudgetingModule(); })},
            'newbudgetalarm': function (){return import(/* webpackChunkName: "categorybudgeting" */ './../modules/newbudgetalarm/newbudgetalarm.module').then(({ NewBudgetAlarmModule }) => {
                return new NewBudgetAlarmModule(); })},
            'userpassword': function (){return import(/* webpackChunkName: "userpassword" */ './../modules/userpassword/userpassword.module').then(({ UserPasswordModule }) => {
                return new UserPasswordModule(); })},
            'categoryshared': function (){return import(/* webpackChunkName: "categoryshared" */ './../modules/categoryshared/categoryshared.module').then(({ CategorySharedModule }) => {
                    return new CategorySharedModule(); })},
            'fixedgasto': function (){return import(/* webpackChunkName: "fixedgasto" */ './../modules/fixedgasto/fixedgasto.module').then(({ FixedGastoModule }) => {
                return new FixedGastoModule(); })},
            'fixedgastoadd': function (){return import(/* webpackChunkName: "fixedaddgasto" */ './../modules/fixedgastoadd/fixedgastoadd.module').then(({ FixedGastoAddModule }) => {
                return new FixedGastoAddModule(); })},
            'personalinfo': function (){return import(/* webpackChunkName: "personalinfo" */ './../modules/personalinfo/personalinfo.module').then(({ PersonalInfoModule }) => {
                return new PersonalInfoModule(); })},
        };
    }

    loadOnDemandModules(arraytomoduleid, callback){
        if(arraytomoduleid.length > 0) {
            let downloadpromises = [];
            arraytomoduleid.forEach(moduleid => {
                if(!this.views[moduleid]){
                    downloadpromises.push(this.onDemandModules[moduleid]());
                }    
            });
            let viewcontainer = document.querySelector('.viewscontainer');
            let iconcontainer  =document.querySelector('.icon-container-def');
                    
            Promise.all(downloadpromises)
            .then((downloadedmodules)=> {
                downloadedmodules.forEach(mod => {
                    mod.onDocumentLoad(viewcontainer, iconcontainer);
                    this.registerView(mod);
                    this.UIManager._viewDictionary[mod.id] = mod.getGraph();
                    this.UIManager.views[mod.id] = mod;
                    mod.init();
                    mod.setUserToView(this.loggedUser);
                });
                callback(downloadedmodules.map(mod => mod.id));
            })
        }
        
    }

    registerView(module) {
        module.setStore(this);
        module.setUIManager(this.UIManager);
        module.setServices(this.services);
        // module.init();
        this.views[module.id] = module;
        // Check for scroll function
        if(module.scrollEvent) {
            this.scrollModules.push(module);
        }
    }

    _loadViewGraph() {
        let viewGraph = {};
        for (const idview in this.views) {
            if (this.views.hasOwnProperty(idview)) {
                viewGraph[idview] = this.views[idview].getGraph();
            }
        }
        this.UIManager.loadNewViews(viewGraph, this.views);
    }

    loadIconSets() {
        this.iconSets[ICON_CATEGORY_SET.name] = ICON_CATEGORY_SET.icons;
        Object.values(ICON_CATEGORY_SET.icons).forEach(icon=>{
            let newid = ICON_CATEGORY_SET.name + '.' + icon.id
            this.iconByID[newid] = icon;
        })
    }

    Service(servicename) {
        return this.services[servicename];
    }

    Component(componentType){
        return this.components.Component(componentType);
    }

    View(viewname) {
        return this.views[viewname];
    }

    _InitModules(store) {
        return new Promise(function (resolve, reject) {
            // Attach Views And Icons
            let viewcontainer = document.querySelector('.viewscontainer');
            let iconcontainer  =document.querySelector('.icon-container-def');
            import(/* webpackChunkName: "appcomponents" */  './../components/components.module')
            .then(({ AppComponentsModule }) => {
                store.components = new AppComponentsModule();
                Object.values(store.views).forEach(mod => {
                    mod.onDocumentLoad(viewcontainer, iconcontainer);
                    if (mod.isAvailable){
                        mod.init();
                    } else {
                        console.warn('module not available');
                    }
                    
                });
                resolve(true);
            });
        });
        
        

    }

    bottomBarAction(action) {        
        switch (action) {
            case 'manualentry':
                this.pushState(PUSH_STATES.MANUAL_ENTRY);
                break;
            case 'main':
                if(this.currentState.id == 'push') {
                    (function (store) {
                        store.cleanStatePush('push', function () {
                            store.setNewState(new MainState(store), MAIN_STATES.UNCATEGORIZED);
                        });
                    })(this)
                    
                    
                }
                break;
            case 'categories':
                this.pushState(PUSH_STATES.CATEGORY);
                break;
        }
    }

    start() {
        let that = this;
        this._InitModules(this)
        .then(function () {
            that._loadViewGraph();
            return that.Service('User').init()
        })
        .then(userlogged => {
            this.currentState = new InitialState(this);
            if(userlogged) {
                this.setLoggedUser(userlogged);
                this.currentState.performAction(INITIAL_STATES.USER_LOGGED, userlogged);
            }else {
                // Check if the blog exist
                if(document.querySelector('.blogview'))
                {
                    this.currentState.performAction(INITIAL_STATES.BLOGGING);
                } else {
                    this.currentState.performAction(INITIAL_STATES.LANDING);
                }
                // this.currentState.performAction(INITIAL_STATES.TESTING);
            }
        });
    }

    setNewState(state, action, data = null) {
        // this.cleanHistory();
        this.currentState = state;
        this.currentState.performAction(action, data);
    }

    pushStateAction(action, data=null) {
        this.currentState.performAction(action, data);
    }

    pushState(state) {
        // saved
        this.savedState = this.currentState;
        this.savedView = this.UIManager._currentNode.current.id;

        // Save current state
        this.currentState = new PushState(this);
        this.currentState.performAction(state);
    }

    backPushState() {
        this.currentState = this.savedState;
        this.UIManager.viewTransition(this.savedView);
    }

    setLoggedUser(user) {
        this.loggedUser = user;
        this.bottomBar.setActive(true);
        this.UIManager.setActiveSideViews(true);
        Object.values(this.views).forEach(viewmodule => {
            viewmodule.setUserToView(user);
        });
    }

    cleanStatePush(stateId, callback) {
        this.cleanHistoryState.stateId = stateId;
        this.cleanHistoryState.cleaning = true;
        this.cleanHistoryState.callback = callback;
        window.history.back();
    }

    secuentialCleaning() {
        window.history.back();
    }

    onPopState(event, store) {
        if(store.cleanHistoryState.cleaning){
            if(event.state.stateId != store.cleanHistoryState.stateId) {
                store.cleanHistoryState.cleaning = false;
                if(store.cleanHistoryState.callback) {
                    store.cleanHistoryState.callback();
                }
            } else {
                store.secuentialCleaning()
            }
        } else {
            if(event.state) {
                if(!store.historyIgnoreFlag) {
                    store.currentState.backAction(event.state.backAction, event.state.data);
                } else {
                    store.historyIgnoreFlag = false;
                }
            }
        }
        
    }

    backHistoryState() {
        window.history.back();
    }

    ignoreHistoryBack() {
        this.historyIgnoreFlag = true;
        this.backHistoryState();
    }

    pushHistoryState(data, url) {
        window.history.replaceState(data, '');
        window.history.pushState({}, '', url);
    }

    urlBase64ToUint8Array(base64String) {
        var padding = '='.repeat((4 - base64String.length % 4) % 4);
        var base64 = (base64String + padding)
          .replace(/\-/g, '+')
          .replace(/_/g, '/');
      
        var rawData = window.atob(base64);
        var outputArray = new Uint8Array(rawData.length);
      
        for (var i = 0; i < rawData.length; ++i) {
          outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    registerPushNotifications(store) {
        return new Promise(function (resolve, reject) {
            if('Notification' in window) {
                if(!('serviceWorker' in navigator)){
                    resolve(null);
                }else {
                    let reg;
                    navigator.serviceWorker.ready
                    .then(function (swreg) {
                        reg = swreg;
                        return swreg.pushManager.getSubscription();
                    })
                    .then(function(subscriptions) {
                        if(subscriptions == null) {
                            let publicVapid = (store.modDev ? 'BIl4bE2Fhm6Z-cnqTM7Xy9zF_TlgUShgWS0kptS21rRp0SaDZP3_grHTwrgVFQX1Zpr9Qjd4pfgE60hZm8fnN08' : 'BBG22mK7pAvAnbD5NZWE9FDODegsGBrsCZVb0Nk0P2FAsEREvGZZjPr_Kxr2PmZBsEpqJiu4rAU6vGSfzOaOwsA');
                            let convertedVapid = store.urlBase64ToUint8Array(publicVapid);
                            return reg.pushManager.subscribe({
                                userVisibleOnly: true,
                                applicationServerKey: convertedVapid
                            })
                            .then((newsubscripiton) => {
                                return store.Service('User').saveNotificationSubscription(newsubscripiton)
                                .then((serversaved)=>{
                                    resolve(newsubscripiton);
                                })
                            })
                        } else {
                            resolve(subscriptions);
                        }
                    });
                }
            }else {
                resolve(null);
            }
        });
    }

    checkPushNotifications() {
        return new Promise(function (resolve, reject) {
            // Verify if API is available
            if('Notification' in window) {
                if(!('serviceWorker' in navigator)){
                    resolve(null);
                }else {
                    navigator.serviceWorker.ready
                    .then(function (swreg) {
                        return swreg.pushManager.getSubscription();
                    })
                    .then(function(subscriptions) {
                        if(subscriptions == null) {
                            resolve({hasSubscription: false});
                        } else {
                            resolve({hasSubscription: true});
                        }
                    });
                }
            }else {
                resolve(false);
            }
        });
    }

    displayConfirmNotification(title, notificationtext) {
        if ('serviceWorker' in navigator) {
            var options = {
            body: notificationtext,
            icon: '/images/icons/icon-96x96.png',
            dir: 'ltr',
            vibrate: [100, 50],
            badge: '/images/icons/icon-96x96.png',
            tag: 'confirm-notification',
            renotify: true,
            };
        
            navigator.serviceWorker.ready
            .then(function(swreg) {
                swreg.showNotification(title, options);
            });
        }
    }

    cleanHistory() {
        // console.log(window.history.state);
        // while(window.history.state != null)
        //     window.history.back();
    }
}
