export class BasicState{

    constructor(id, appstore) {
        this.id = id;
        this.previousAction = null;
        this.Store = appstore;
        this.urlparameters = new URLSearchParams(window.location.search);
    }

    performAction(action, data) {
        console.log(this.id + ' state not code');
    }

    backAction(action, data) {
        console.log(this.id + ' backActionstate not code');
    }

    nextState() {
        console.log(this.id + ' nextState not coded');
    }

    createBackAction (backaction = 0, data = null, state) {
        return {
            backAction: backaction,
            data,
            stateId: this.id
        };
    }
}