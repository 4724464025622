import { BasicView } from '../basicview.class';
import {MDCRipple} from '@material/ripple';
import { INITIAL_STATES } from '../../store/states/initial.state';
// import ScrollMagic from 'scrollmagic';

const BLOG_POST_TPL = `<a href="%URLBLOG%">
<div class="blog-post">
  <img src="%IMGURL%" alt="%BLOGNAME%">
  <div class="title">
    <span>%BLOGNAME%</span>
  </div>
</div>
</a>`;

export class LandingModule extends BasicView {
    constructor() {
        // Call to the super class
        super(
            'landing',
        );
        this.dom = document.querySelector('.landingview');
    }
    
    getGraph() {
        return {left: [], current: this.getViewObject(), right: ['login']};
    }

    init() {
        this.signupaction = new MDCRipple(this.dom.querySelector('.signupaction'));
        this.loginaction = new MDCRipple(this.dom.querySelector('.login-action'));
        this.windowHeight = window.innerHeight|| document.documentElement.clientHeight|| document.body.clientHeight;
        this.animationDuration = this.windowHeight;
        this.sectionAnimationDuration = this.animationDuration;
        this.sectionDimout = this.sectionAnimationDuration / 5;
        this.previoustopvalue = 0;
        
        this.animationSectionRanges = [
            [this.animationDuration, this.animationDuration + this.sectionAnimationDuration, (this.animationDuration + this.sectionAnimationDuration - this.sectionDimout)],
            [this.animationDuration + this.sectionAnimationDuration, this.animationDuration +  2 * this.sectionAnimationDuration, (this.animationDuration +  2 * this.sectionAnimationDuration - this.sectionDimout)],
            [this.animationDuration +  2 * this.sectionAnimationDuration, (this.animationDuration +  3 * this.sectionAnimationDuration), (this.animationDuration +  3 * this.sectionAnimationDuration - this.sectionDimout)]
        ];
        

        (function (that) {
            that.dom.querySelector('.signupaction').addEventListener('click', function (){
                that.Store.currentState.performAction(INITIAL_STATES.LOGGING, {isSignUp: true, cleanField: true});
            }, false);
            that.dom.querySelector('.login-action').addEventListener('click', function (){
                that.Store.currentState.performAction(INITIAL_STATES.LOGGING, {isLogin: true, cleanField: true});
            }, false);
            // that.dom.querySelector('.go-salud-section').addEventListener('click', function () {
            //     window.location='#salud-section';
            // }, false);
            that.dom.querySelector('.start-action').addEventListener('click', function () {
                that.Store.currentState.performAction(INITIAL_STATES.LOGGING, {isSignUp: true, cleanField: true});
            }, false)
        })(this);
    }

    loadLanding(){
        this.Store.Service('Blog').getBlogTop()
        .then(allposts => {
            // this.cleanContainer(this.dom.querySelector('.blog-list'));
            allposts.forEach(p => {
                let bpstring = BLOG_POST_TPL.replace('%URLBLOG%', p.url)
                .replace('%IMGURL%', p.image)
                .replaceAll('%BLOGNAME%', p.name);
                this.dom.querySelector('.blog-list').appendChild(this.createDOM(bpstring));
            });
            this.UIManager.viewTransition('landing');
        });
        
    }
}