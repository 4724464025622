
export class BottomBarComponent {

    constructor(store) {
        this.isActive = false;
        this.store = store;
        this.bottomBarDom = document.querySelector('.tab-bar');
        var btn = document.querySelectorAll('.wave'),
        tab = document.querySelector('.tab-bar'),
        indicatordom = document.querySelector('.indicator'),
        indi = 0;
        indicatordom.style.marginLeft = indi + 'px';
        this.indicatordom = indicatordom;
        this.actions = {};
        this.allbuts = document.querySelectorAll('.wave');
        btn.forEach(singlebut => {
            (function (button, allbuttons, indicator,sto){
                button.addEventListener('click', function (e) {
                    sto.bottomBarAction(button.dataset.viewid);
                    allbuttons.forEach(ab => {
                        if(button.dataset.viewid != ab.dataset.viewid && ab.classList.contains('selected')) {
                            ab.classList.remove('selected');
                        }
                    });
                    indicator.style.marginLeft = indi + (button.dataset.num-1) * 33.333 + '%';
                    button.classList.add('selected');
                }, false);
            })(singlebut, btn, indicatordom, store);
        });
        let previous = 0;
        (function (wp, b, pre) {
            wp.onscroll = function (e){
                if(pre > wp.scrollTop){
                    b.style.bottom = "0";
                } else {
                    b.style.bottom = "-44px";
                }
                pre = wp.scrollTop
            };
        })(document.querySelector('.main-content'), tab, previous)
    }

    setActive(value) {
        if(value != this.isActive){
            this.isActive = value;
            if(value) {
                this.bottomBarDom.style = '';
            } else {
                this.bottomBarDom.style = 'display:none;';
            }
        }
    }

    setSelected(viewid) {
        this.allbuts.forEach(btn => {
            if(btn.dataset.viewid == viewid && !btn.classList.contains('selected')) {
                this.indicatordom.style.marginLeft =  (btn.dataset.num-1) * 33.33 + '%';
                btn.classList.add('selected');
            } else {
                if(btn.classList.contains('selected')){
                    btn.classList.remove('selected');
                }
            }
        });
    }


}