const USER_TOKENS = 'ge_user';
export class ServiceConnection {

    

    constructor(serverurl) {
        this.ServerURL = serverurl;
        this.API = 'api/v1';
        this.tokens = null;
    }

    tryRefreshToken() {
        return new Promise(function (resolve, reject) {
            var xmlhttp = new XMLHttpRequest();
            xmlhttp.open(method, currentObject.ServerURL + currentObject.API + api);
            xmlhttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            xmlhttp.send(JSON.stringify(data));
        })
    }


    createPromiseConnection (api, data, method = 'POST') {
        let currentObject = this;
        return new Promise(function (resolve, reject) {
            var xmlhttp = new XMLHttpRequest();
            xmlhttp.open(method, currentObject.ServerURL + currentObject.API + api);
            xmlhttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
            if (currentObject.tokens && currentObject.tokens.accessToken) {
                xmlhttp.setRequestHeader('x-access-token', currentObject.tokens.accessToken);
            }
            xmlhttp.send(JSON.stringify(data));
            xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState === 4) {
                if ([200, 201].indexOf(xmlhttp.status) >= 0) {
                var resp = xmlhttp.responseText;
                var respJson = JSON.parse(resp);
                // Check to refresh tokens
                // if (respJson.hasOwnProperty('tokenExpired')) {

                //     // currentObject.setUserAccessToken(respJson.tokenupdate);
                //     // localforage.setItem(LF_USER_TOKENS, respJson.tokenupdate);
                //     delete respJson.tokenupdate;
                // }
                resolve(respJson);
                } else {
                    resolve({result: false, responseStatus:xmlhttp.status});
                }
            }
            };
        });
    }

    setToken(tokens) {
        this.tokens = tokens;
    }
}
