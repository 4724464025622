import localforage from "localforage";

const TRANSITION_RIGHT = 1;
const TRANSITION_LEFT = 2;
const LOCATION_ITEMS = 'loc_data';

export class ViewModule {
    constructor (store) {
        this.views= null;
        this._testvar = {};
        // Variables
        this._currentNode = {};
        this._viewsOrganization = [];
        this._sideViews = [];
        this._sideViewsActive = false;
        this._viewDictionary = {};
        this._transitionClasses = {
          currentview: 'currentsection',
          rightcommingview: 'rightcommingsection',
          leftcommingview: 'leftcommingsection',
          righttransition: 'rightchangeview',
          lefttransition: 'leftchangeview'
        };
        this._topBarSection = document.querySelector('.drawer-header-flex');
        this._mainContentData = document.querySelector('.main-content-data');
        this._appDrawer = document.querySelector('.appdrawer');
        this._locations = null;
        this._cleaningTimeOut = {};
        this.popTransitions = {};
        this.store = store;
    }
  
    /**
     * Adds a new view 
     * @param {*} views 
     * @param {*} index 
     */
    addViewToDictionary (views) {
      this._viewDictionary = views;
    }

    setSideView(viewid){
      if(this._sideViews.indexOf(viewid) < 0){
        this._sideViews.push(viewid);
      }
    }

    setActiveSideViews(active){
      this._sideViewsActive = active;
    }
  
    updateCurrentNode (newnode) {
      this._currentNode.current = newnode.current;
      this._currentNode.right = newnode.right;
      this._currentNode.left = newnode.left;
    }
  
    /**
     * Adding new views into the organization
     * @param {*} organizeviewarray 
     * @param {*} sideinsert 
     */
    loadNewViews (organizeviewarray, views) {
      for (const idview in organizeviewarray) {
        if (organizeviewarray.hasOwnProperty(idview)) {
          if (organizeviewarray[idview].current.dom === null) {
            console.debug('Dom null of ' + idview);
          } else {
            if (organizeviewarray[idview].current.dom.classList.contains('currentsection')) {
              this.updateCurrentNode(organizeviewarray[idview]);
            }
          }
        }
      }
      this.addViewToDictionary(organizeviewarray);
      this.views = views;
    }
  
    /**
     *
     * @param {*} domvalue 
     */
    cleantransitionclasses (domvalue) {
      for (var classkey in this._transitionClasses) {
        if (this._transitionClasses.hasOwnProperty(classkey)) {
          var classvalue = this._transitionClasses[classkey];
          if (domvalue.classList.contains(classvalue)) {
            domvalue.classList.remove(classvalue);
          }
        }
      }
    }
  
    updateClassesViewNode (nodes, show, addclasses, removeclass) {
      // set the left transtion
      for (const idnode in nodes) {
        if (nodes.hasOwnProperty(idnode)) {
          const node = this.views[nodes[idnode]];
          node.dom.classList.add('no-transition');
          node.dom.classList.remove(removeclass.join(','));
          node.dom.classList.add(addclasses.join(','));
          if (show) {
            node.dom.removeAttribute('hidden');
          } else {
            node.dom.set('hidden', true);
          }
          node.dom.offsetHeight;
          node.dom.classList.remove('no-transition');
        }
      }
    }
  
    /**
         * This function is going to update the views
         * @param {*} index 
         * @param {*} arrayviews 
         */
    prepareForNextTransition (oldnode, currentnode) {
      // console.log('_prepareForNextTransition', index);
      // Clean old node
      let allviews = [];
      Array.prototype.push.apply(allviews, oldnode.left);
      Array.prototype.push.apply(allviews, oldnode.right);
      for (let indexviewus = 0; indexviewus < allviews.length; indexviewus++) {
        const tocleannode = this.views[allviews[indexviewus]];
        if (tocleannode.id !== currentnode.current.id) {
          tocleannode.dom.setAttribute('hidden', true);
          this.cleantransitionclasses(tocleannode.dom);
        }
      }
      let rightSideModules = currentnode.right;
      let leftSideModules = currentnode.left;
      if(this.popTransitions[currentnode.current.id]){
        this.popTransitions[currentnode.current.id].right.forEach(moduleid => {if(rightSideModules.indexOf(moduleid) < 0){rightSideModules.push(moduleid)}});
        this.popTransitions[currentnode.current.id].left.forEach(moduleid => {if(leftSideModules.indexOf(moduleid) < 0){leftSideModules.push(moduleid)}});
      }
      // Check for side views
      if (this._sideViewsActive) {
        this._sideViews.forEach(view => {
          let idsideview = view.current.id;
          if (currentnode.current.id != idsideview && leftSideModules.indexOf(view.current.id) < 0) {
            leftSideModules.push(idsideview);
          }
        });
      }
      // Check if nodes are available
      let rightnodes = {available:[], download:[]};
      currentnode.right.forEach(nodeid => {
        if(this.views[nodeid]) {
          rightnodes.available.push(nodeid);
        } else {
          rightnodes.download.push(nodeid);
        }
      });
      this.updateClassesViewNode(rightnodes.available, true, [this._transitionClasses.rightcommingview], [this._transitionClasses.leftcommingview]);
      if(rightnodes.download.length > 0){
        let that = this;
        this.store.loadOnDemandModules(rightnodes.download, function (downloadedmodules) {
          that.updateClassesViewNode(downloadedmodules, true, [that._transitionClasses.rightcommingview], [that._transitionClasses.leftcommingview]);
        });
      }
      
      
      let leftnodes = {available:[], download:[]};
      currentnode.left.forEach(nodeid => {
        if(this.views[nodeid]) {
          leftnodes.available.push(nodeid);
        } else {
          leftnodes.download.push(nodeid);
        }
      });
      this.updateClassesViewNode(leftnodes.available, true, [this._transitionClasses.leftcommingview], [this._transitionClasses.rightcommingview]);
      if(leftnodes.download.length > 0){
        let that = this;
        this.store.loadOnDemandModules(leftnodes.download, function (downloadedmodules) {
          that.updateClassesViewNode(downloadedmodules, true, [that._transitionClasses.leftcommingview], [that._transitionClasses.rightcommingview]);
        });
      }
    }
  
    getDirection (nodeview) {
      for (let indexrightview = 0; indexrightview < this._currentNode.right.length; indexrightview++) {
        if (this._currentNode.right[indexrightview] === nodeview.current.id) {
          return TRANSITION_RIGHT;
        }
      }
      for (let indexleft = 0; indexleft < this._currentNode.left.length; indexleft++) {
        if (this._currentNode.left[indexleft] === nodeview.current.id) {
          return TRANSITION_LEFT;
        }
      }
      return 0;
    }
  
    setOnlyVisibleFromObject (nodetovisible, allobjects) {
      for (const idonode in allobjects) {
        if (allobjects.hasOwnProperty(idonode)) {
          const nodeview = this.views[allobjects[idonode]].getViewObject();
          if (nodeview.id === nodetovisible.current.id) {
            nodeview.dom.removeAttribute('hidden');
          } else {
            nodeview.dom.setAttribute('hidden', true);
          }
        }
      }
    }
  
    /**
     * Make the transition
     * @param {*} currentview 
     * @param {*} commingview 
     * @param {*} transition 
     * @param {*} indexviewus 
     */
    
    viewTransition (viewnodeid, pushedleft =[], pushedright=[]) {
      if (viewnodeid !== this._currentNode.current.id && !this._cleaningTimeOut.id) {

        // Get the data
        let viewnode = this._viewDictionary[viewnodeid];
        let direction = this.getDirection(viewnode);
        if('gtag' in window) {
          gtag('event', 'page_view', {
            page_title: viewnodeid,
          })
        }

        this._currentNode.current.dom.parentNode.scrollTop = 0; // For Safari
        // Get the current nodes on both sides
        let rightviews = this._currentNode.right;
        let leftviews = this._currentNode.left;
        //check popviews
        if(this.popTransitions[this._currentNode.current.id]) {
          this.popTransitions[this._currentNode.current.id].right.forEach(moduleid => {if(rightviews.indexOf(moduleid) < 0){rightviews.push(moduleid)}});
          this.popTransitions[this._currentNode.current.id].left.forEach(moduleid => {if(leftviews.indexOf(moduleid) < 0){leftviews.push(moduleid)}});
          delete this.popTransitions[this._currentNode.current.id];
        }
        // Perform screen change 
        switch (direction) {
          case TRANSITION_RIGHT:
            this.setOnlyVisibleFromObject(viewnode, rightviews);
            this._currentNode.current.dom.parentNode.classList.add('rightchangeview');
            break;
          case TRANSITION_LEFT:
            this.setOnlyVisibleFromObject(viewnode, leftviews);
            this._currentNode.current.dom.parentNode.classList.add('leftchangeview');
            break;
        }
        // Clean and prepare for next transition
        this._cleaningTimeOut.id = setTimeout(function () {
          this._currentNode.current.dom.setAttribute('hidden', true);
          this.cleantransitionclasses(viewnode.current.dom);
          this.cleantransitionclasses(this._currentNode.current.dom);
          this.cleantransitionclasses(this._currentNode.current.dom.parentNode);
          this.prepareForNextTransition(this._viewDictionary[this._currentNode.current.id], this._viewDictionary[viewnode.current.id]);
          viewnode.current.dom.classList.add(this._transitionClasses.currentview);
          this.updateCurrentNode(this._viewDictionary[viewnode.current.id]);
          this._cleaningTimeOut.id = null;
        }.bind(this), 500);
      }
    }

    // This function is to add transition one-time before poping out the transition in the preparation
    popTransition(target, graph) {
      this.popTransitions[target] = graph;
    }
    /**
     * Function to show the header top bar
     * @param {*} shownotshow 
     */
    showUserLoggedUI (shownotshow) {
      if (shownotshow) {
        this._topBarSection.classList.add('animating');
        this._mainContentData.classList.add('mdc-top-app-bar--fixed-adjust');
      } else {
        this._topBarSection.classList.remove('animating');
        this._mainContentData.classList.remove('mdc-top-app-bar--fixed-adjust');
      }
    }
  
    changeHeaderToSection (sectionName) {
      this._topBarSection.querySelector('.mainheader').style.display = 'none';
      this._topBarSection.querySelector('.sectionheader').querySelector('.mdc-top-app-bar__title').textContent = sectionName;
      this._topBarSection.querySelector('.sectionheader').style.display = 'inline-flex';
    }
  
    isCurrentNode (node) {
      return node.id === this._currentNode.current.id;
    }
  
    /**
     * Updates all general components that are part of the UI
     * @param {*} user 
     */
    updateUserAppInformation (user) {
      this._appDrawer.querySelector('.namedata').textContent = user.name + ' ' + user.lastname;
    }
  
    /**
     * This function loads the information of the locaitons
     */
    getLocationInfo () {
      return new Promise(function (resolve, reject) {
        // First check local storage
        localforage.getItem(LOCATION_ITEMS)
          .then(function (locationdata) {
            if (locationdata) {
              resolve(locationdata);
            } else {

            }
          });
      });
    }
  }