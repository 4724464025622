
const OPTION_CATEGORY = `<li class="mdc-list-item" aria-selected="false" data-value="CATEGORYID" role="option">
<span class="mdc-list-item__text">CATEGORYNAME</span>
</li>`;

export class CategoryService {
    constructor(serverconnection) {
        this.serverConnection = serverconnection;
    }

    getCategories() {
        return this.serverConnection.createPromiseConnection('/category', {}, 'POST')
        .then(result => {
            let allcategories = result.categories;
            return allcategories;
        });
    }

    createCategory(category) {
        return this.serverConnection.createPromiseConnection('/category/create', {category}, 'POST')
        .then(result => {
            let {newCategory, categories} = result;
            return newCategory;
        });
    }

    createBulk(categories) {
        return this.serverConnection.createPromiseConnection('/category/bulkcreate', categories, 'POST')
        .then(resultdata=> {
            if (resultdata.result) {
                let allcategories = resultdata.categories;
                return allcategories;
            }
        })
    }

    createDOMOptionFromCategories(categories) {
        return [...categories.myCategories, ...categories.sharedCategories].map(cat => OPTION_CATEGORY.replace('CATEGORYID', cat.id).replace('CATEGORYNAME', cat.name));
    }

    getGeneralInfo(category) {
        return this.serverConnection.createPromiseConnection('/category/generalinfo', category, 'POST')
        .then((generalinfo)=> {
            return generalinfo;
        });
    }

    editCategory(category) {
        return this.serverConnection.createPromiseConnection('/category/edit', category, 'POST')
        .then((updatedcategory)=> {
            return updatedcategory;
        });
    }

    getCategoryBusinesses(category) {
        return this.serverConnection.createPromiseConnection('/category/businesses', category, 'POST')
        .then((data)=> {
            if(data && data.businesses) {
                return data.businesses;
            }
            return null;
        });
    }

    delete(category) {
        return this.serverConnection.createPromiseConnection('/category/delete', category, 'POST')
        .then((data)=> {
            return data.result;
        });
    }

    updateBusinessCategory(businessToUpdate) {
        return this.serverConnection.createPromiseConnection('/category/businesscategoryupdate', businessToUpdate, 'POST')
        .then((data)=> {
            return data.result;
        });
    }
    
    addBudgetAlarm(budgetalarm) {
        return this.serverConnection.createPromiseConnection('/category/budget/alarm/new', budgetalarm, 'POST')
        .then(({alarm})=> {
            return alarm;
        })
    }

    deleteBudgetAlarm(budgetalarm) {
        return this.serverConnection.createPromiseConnection('/category/budget/alarm/delete', budgetalarm, 'POST')
        .then(({result})=> {
            return result;
        })
    }

    updateBudget(category) {
        return this.serverConnection.createPromiseConnection('/category/budget', category, 'POST')
        .then(({result})=> {
            return result;
        });
    }

    createCategoryInvitations(category, invitations) {
        return this.serverConnection.createPromiseConnection('/category/shared/invitation/create', {category, invitations}, 'POST')
        .then(({result})=> {
            return result;
        });
    }

    getPendingCategoryInvitations() {
        return this.serverConnection.createPromiseConnection('/category/shared/invitation/pending', {}, 'POST')
        .then(({invitations})=> {
            return invitations;
        });
    }

    setActionToCategoryInvitation(invitation, action) {
        return this.serverConnection.createPromiseConnection('/category/shared/invitation/action', {invitation, action}, 'POST')
        .then(({result})=>{
            return result;
        });
    }

    leaveSharedCategory(category) {
        return this.serverConnection.createPromiseConnection('/category/shared/leave', category, 'POST')
        .then(({result})=>{
            return result;
        });
    }
}