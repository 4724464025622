import style from './../css/app.scss'

import { AppStore } from './store/app.store';
import { LoadingModule } from './modules/loading/loading.module';
import { LandingModule } from './modules/landing/landing.module';
import { LoginModule } from './modules/login/login.module';
import { UncategorizedModule } from './modules/uncategorized/uncategorized.module';
import { UserModule } from './modules/user/user.module';
import { CategoryPendingInvitationsModule } from './modules/categorypendinginvitations/categorypendinginvitations.module';
import { ExpiredSubscriptionModule } from './modules/expiredsubscription/expiredsubscription.module';
import { BlogModule } from './modules/blog/blog.module';

let appstore = new AppStore();
// Set the history changes events
(function (store){
  window.onpopstate = function(event){
    store.onPopState(event, store);
  };
  window.onload = function (event){
    store.start();
  }
})(appstore);

// Load Services
appstore.initServices();
// Views
appstore.registerView(new LoadingModule());
appstore.registerView(new LandingModule());
appstore.registerView(new LoginModule());
appstore.registerView(new UncategorizedModule());
appstore.registerView(new UserModule());
appstore.registerView(new CategoryPendingInvitationsModule());
appstore.registerView(new ExpiredSubscriptionModule());




import(/* webpackMode: "lazy", webpackChunkName: "mainmodule", webpackPreload: true */ './modules/main/main.module')
.then(({MainModule})=>{
  appstore.registerView(new MainModule());
});

import(/* webpackMode: "lazy", webpackChunkName: "manualentry", webpackPreload: true */ './modules//manualentry/manualentry.module')
.then(({ManualEntryModule})=>{
  appstore.registerView(new ManualEntryModule());
});

import(/* webpackMode: "lazy", webpackChunkName: "iconpicker", webpackPreload: true */ './modules/iconpicker/iconpicker.module')
.then(({IconPickerModule})=>{
  appstore.registerView(new IconPickerModule());
});
import(/* webpackChunkName: "categoryonboarding", webpackPreload: true */ './modules/categoryonboarding/categoryonboarding.module')
.then(({CategoryOnBoardingModule})=>{
  appstore.registerView(new CategoryOnBoardingModule());
});

import(/* webpackChunkName: "detailed", webpackPreload: true */ './modules/detailed/detailed.module')
.then(({DetailedModule})=>{
  appstore.registerView(new DetailedModule());
});

import(/* webpackChunkName: "category", webpackPreload: true */ './modules/category/category.module')
.then(({CategoryModule})=>{
  appstore.registerView(new CategoryModule());
});
appstore.loadIconSets();

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js').then(registration => {
      console.log('SW registered: ', registration);
    }).catch(registrationError => {
      console.log('SW registration failed: ', registrationError);
    });
  });
}