import localforage from "localforage";

const USER_TOKENS = 'ge_user';

export class UserService {
    
    constructor(serverconnection) {
        this.serverConnection = serverconnection;
        this.userLogged = null;
    }

    init () {
        // Check for tokens in the background
        let that = this;
        return localforage.getItem(USER_TOKENS)
        .then(function (tokens) {
            if(tokens) {
                that.serverConnection.setToken(tokens);
                return that.pingUser()
                .then(result => {
                    if(!(result)) {
                        return localforage.removeItem(USER_TOKENS)
                        .then(()=> {
                            return null;
                        });
                    }else {
                        return result;
                    }
                });
            }
            return null;

        });
    }

    checkUserName(username) {
        return this.serverConnection.createPromiseConnection('/user/checkusername',{username})
        .then(data => {
            if(data.result){
                return data.taken;
            }
            return true;
        });
    }

    checkEmail(email) {
        return this.serverConnection.createPromiseConnection('/user/checkemail',{email})
        .then(data => {
            if(data.result){
                return data.taken;
            }
            return true;
        });
    }

    pingUser() {
        return this.serverConnection.createPromiseConnection('/user',{}, 'POST')
        .then(data => {
            if (data.result) {
                this.userLogged = data.user;
                return this.userLogged;
            }
            return null;
        });
    }

    signInUser(email, password){
        return this.serverConnection.createPromiseConnection('/user/signin',{user: {email, password}})
        .then(data => {
            if(data.result) {
                this.serverConnection.setToken(data.tokens);
                // safe tokens
                localforage.setItem(USER_TOKENS, data.tokens, function(result) {
                })
                this.userLogged = data.user;
                return data.user;
            }
            return null;
        });
    }

    createNewUser(user) {
        let that = this;
        return this.serverConnection.createPromiseConnection('/user/create', user)
        .then(response => {
            if(response.result) {
                return localforage.setItem(USER_TOKENS, response.tokens, function(datasetting) {
                    that.serverConnection.setToken(response.tokens);
                    return response.userinfo;
                })
                .then(function () {
                    return response.userinfo;
                });
            }
            return null;
        });
    }

    saveNotificationSubscription(subscription){
        return this.serverConnection.createPromiseConnection('/user/notificationsubscription', subscription)
        .then(({result}) =>{
            return result;
        });
    }

    updateUserInfo(user) {
        return this.serverConnection.createPromiseConnection('/user/updateinfo', user)
        .then(({result}) => {
            return result;
        });
    }

    updatePassword(password) {
        return this.serverConnection.createPromiseConnection('/user/updatepassword', password)
        .then(({result}) => {
            return result;
        });
    }
    
    createSubscription()  {
        return this.serverConnection.createPromiseConnection('/user/paypalsubscription/create')
        .then((result)=> {
            return result;
        });
    }
    
}