import { BasicView } from '../basicview.class';
import { PUSH_STATES } from '../../store/states/push.state';
import view from './user.view.html';
import icons from './user.icon.html';
// import ScrollMagic from 'scrollmagic';

export class UserModule extends BasicView {
    constructor() {
        // Call to the super class
        super(
            'user',
            view,
            icons
        );
    }
    
    getGraph() {
        return {left: [], current: this.getViewObject(), right: ['personalinfo', 'fixedgasto', 'main']};
    }

    init() {

        (function (that) {
            that.dom.querySelector('.to-personalinfo').addEventListener('click', function () {
                that.Store.pushStateAction(PUSH_STATES.USER_PROFILE);
            }, false);
            that.dom.querySelector('.to-fixedgasto').addEventListener('click', function () {
                that.Store.pushStateAction(PUSH_STATES.FIXED_GASTO);
            }, false);
            
        })(this)
    }

    setUserToView(user) {
        this.dom.querySelector('.user-name-title').textContent = user.name;
        // this.fields.name.value = user.name;
        // this.fields.surname.value = user.surname;
        // this.fields.hash.value = user.userHash;
    }

    load(){

        this.UIManager.viewTransition('user');
    }
}