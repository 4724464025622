import { BasicView } from '../basicview.class';
import view from './expiredsubscription.view.html';
import icons from './expiredsubscription.icons.html';

export class ExpiredSubscriptionModule extends BasicView {

    constructor() {
        // Call to the super class
        super(
            'expiredsubscription',
        );
        this.dom = document.querySelector('.expiredsubscriptionview');
    }

    init() {        
        ((mod) => {
            this.dom.querySelector('.createsubaction').addEventListener('click', function () {
                mod.Service('User').createSubscription()
                .then( data => {
                    if(data.result) {
                        window.location = data.approvelink;
                    }
                });
            }, false);
        })(this);
        
    }

    getGraph() {
        return {left: [], current: this.getViewObject(), right: []};
    }

    load(){
        this.UIManager.viewTransition('expiredsubscription');
    }
}