import {MDCDrawer} from "@material/drawer";
import { PUSH_STATES } from "../store/states/push.state";

export class DrawerModule {
    constructor () {
        // this.dom = document.querySelector('.mdc-drawer');
        // this.component = MDCDrawer.attachTo(this.dom);
        this.profileAction = null;
        this.categoryAction = null;
        this.store = null;
    }

    setStorage(store) {
        this.store = store;
    }

    init () {
        let that = this;
        // (function(){
        //     that.categoryAction = that.dom.querySelector('.category-action');
        //     that.categoryAction.addEventListener('click', function  () { 
        //         that.component.open = false;
        //         that.store.pushState(PUSH_STATES.CATEGORY);
        //     }, false);
        //     that.dom.querySelector('.farmersm-action').addEventListener('click', function () {
        //         that.component.open = false;
        //         that.store.pushState(PUSH_STATES.CATEGORY);
        //     }, false);
        // })()
    }
}