import { BasicView } from '../basicview.class';
import view from './categorypendinginvitations.view.html';
import icons from './categorypendinginvitations.icons.html';
import { MAIN_STATES } from "../../store/states/main.state";

const INVITATION_TPL = `<div class="add-invitation pending-category-invitation">
<p><strong class="invitation-owner">Javi</strong> te invitó a ser parte de <strong class="category-name">Hogar-Novara</strong></p>
<div class="side-actions">  
  <button class="mdc-button   main-button-oulined action-to-reject  mdc-button--outlined">
      <span class="mdc-button__label">Rechazar</span>
  </button>
  <button class="mdc-button   mdc-button--outlined action-to-approve main-button">
    <div class="mdc-button__ripple"></div>
    <span class="mdc-button__label">Aceptar</span>
  </button>
</div>
</div>`;

export class CategoryPendingInvitationsModule extends BasicView {
    constructor() {
        // Call to the super class
        super(
            'categorypendinginvitations',
            view,
            icons
        );
        // this.dom = document.querySelector('.categorypendinginvitationsview');
    }

    init() {
        this.container = this.dom.querySelector('.invitations');
        this.invitationData = {};

    }

    getGraph() {
        return {left: [], current: this.getViewObject(), right: ['main']};
    }

    actionPerformedToInvitation(invitation, module) {
        // clean the ui
        if(module.invitationData[invitation.id]) {
            module.invitationData[invitation.id].processed = true;
            this.container.removeChild(module.invitationData[invitation.id].dom);
        }
        let remaining = Object.values(module.invitationData).find(invd => invd.processed === false);
        // Check if we are done
        if(!(remaining)) {
            module.Store.pushStateAction(MAIN_STATES.MAIN);
        }
    }

    loadinvitations(invitations) {
        invitations.forEach(inv => {
            let invitationdom = this.createDOM(INVITATION_TPL);
            this.invitationData[inv.id] = {invitation: inv, dom: invitationdom, processed: false};
            invitationdom.querySelector('.invitation-owner').textContent = inv.owner.name;
            invitationdom.querySelector('.category-name').textContent = inv.GastoCategory.name;
            (function(invitationOb, module) {
                invitationdom.querySelector('.action-to-reject').addEventListener('click', function () {
                    module.Service('Category').setActionToCategoryInvitation(invitationOb, false).
                    then(result=> {
                        module.actionPerformedToInvitation(invitationOb, module);
                    })
                }, false);
                invitationdom.querySelector('.action-to-approve').addEventListener('click', function () {
                    module.Service('Category').setActionToCategoryInvitation(invitationOb, true)
                    .then(result=> {
                        module.actionPerformedToInvitation(invitationOb, module);
                    })
                }, false);
            })(inv, this);
            this.container.appendChild(invitationdom);
        });
    }
    
    load(invitations){
        this.cleanContainer(this.container)
        if(invitations) {
            this.loadinvitations(invitations);
        }
        this.UIManager.viewTransition('categorypendinginvitations');
    }
}