const GASTO_TYPE_MANUAL_ENTRY = 4;

export class GastosService {
    constructor(serverconnection) {
        this.serverConnection = serverconnection;
    }

    _createGastoCategoryObject(category){
        let categoryob = category;
        categoryob.totalAmount = 0;
        categoryob.businesses = {};
        categoryob.vouchers = {};
        categoryob.emailreceipts = {};
        categoryob.emailreceiptsbyComerce = {};
        categoryob.manualEntries = [];
        categoryob.AllGastos = [];
        categoryob.users = {};
        if(category.dateGrouping) {
            categoryob.gastosByDates = {};
        }
        return categoryob;
    }
    _createBusiness(business) {
        let bussob = business;
        bussob.isBusiness = true;
        bussob.totalAmount = 0;
        bussob.totalBills = 0;
        bussob.gastos = [];
        bussob.gastosAnotherCurrency = {};
        bussob.users = {};
        return bussob;
    }

    _createVoucher(voucher) {
        let voucherob = voucher;
        voucherob.isVoucher = true;
        voucherob.totalAmount = 0;
        voucherob.totalBills = 0;
        voucherob.gastos = [];
        voucherob.gastosAnotherCurrency = {};
        voucherob.users = {};
        return voucherob;
    }

    _createGastoDate(date) {
        let dateob = {};
        dateob.date = date;
        dateob.totalAmount = 0;
        dateob.businesses = {};
        dateob.vouchers = {};
        dateob.emailreceipts = {};
        dateob.emailreceiptsbyComerce = {};
        dateob.manualEntries = [];
        dateob.AllGastos = [];
        dateob.gastosAnotherCurrency = {};
        dateob.users = {};
        return dateob;
    }

    _addGastoToCurrency(currencies, gasto, amount) {
        // create
        if(!(currencies[gasto.currency])){
            currencies[gasto.currency] = 0;
        }

        currencies[gasto.currency] += amount;
    }

    // This function is to strategize grouping of a category which a container can be a whole category or a date in the category
    _groupGasto(gasto, container){
        // check if the gasto is another currency
        let gastoCost = 0;
        let gastoinanothercurrency = 0;
        let isanothercurrency = (gasto.exchangeColones ? true : false);

        if(isanothercurrency) {
            gastoinanothercurrency = parseFloat(gasto.amount) 
            gastoCost = parseFloat(gasto.exchangeColones) * gastoinanothercurrency;
        } else{
            gastoCost = parseFloat(gasto.amount);
        }
        

        if(gasto.BusinessId) {
            if(!(gasto.BusinessId in container.businesses)) {
                container.businesses[gasto.BusinessId] = this._createBusiness(gasto.Business);
            }
            if(!(container.businesses[gasto.BusinessId].users[gasto.UserId])) {
                container.businesses[gasto.BusinessId].users[gasto.UserId] = {total: 0, id: gasto.UserId};
            }
            container.businesses[gasto.BusinessId].users[gasto.UserId].total += gastoCost;
            container.businesses[gasto.BusinessId].totalAmount += gastoCost;
            container.businesses[gasto.BusinessId].totalBills++;

            // Another currency
            if(isanothercurrency) {
                this._addGastoToCurrency(container.businesses[gasto.BusinessId].gastosAnotherCurrency, gasto, gastoinanothercurrency);
            }

            container.businesses[gasto.BusinessId].gastos.push(gasto);
            
        } else if(gasto.voucher) {
            if(!(gasto.voucher.terminal in container.vouchers)) {
                container.vouchers[gasto.voucher.terminal] = this._createVoucher(gasto.voucher);
            }
            if(!(container.vouchers[gasto.voucher.terminal].users[gasto.UserId])) {
                container.vouchers[gasto.voucher.terminal].users[gasto.UserId] = {total: 0, id: gasto.UserId};
            }
            container.vouchers[gasto.voucher.terminal].users[gasto.UserId] += gastoCost;
            container.vouchers[gasto.voucher.terminal].totalAmount += gastoCost;
            container.vouchers[gasto.voucher.terminal].totalBills++;

            // Another currency
            if(isanothercurrency) {
                this._addGastoToCurrency(container.vouchers[gasto.voucher.terminal].gastosAnotherCurrency, gasto, gastoinanothercurrency);
            }

            container.vouchers[gasto.voucher.terminal].gastos.push(gasto);
        } else if(gasto.emailReceipt) { 
            let emailSourceId = gasto.emailReceipt.EmailSource.id;
            let comercioMetadata = gasto.emailReceipt.EmailReceiptMetadata.find(metadata => metadata.key === 'Comercio');
            if (comercioMetadata) {
                let comercioname = comercioMetadata.value
                if(!(comercioname in container.emailreceiptsbyComerce)) {
                    container.emailreceiptsbyComerce[comercioname] = {};
                    container.emailreceiptsbyComerce[comercioname].receipts = [];   
                    container.emailreceiptsbyComerce[comercioname].isEmailReceiptCommerce = true;
                    container.emailreceiptsbyComerce[comercioname].commerceName = comercioname;
                    container.emailreceiptsbyComerce[comercioname].totalAmount = 0;
                    container.emailreceiptsbyComerce[comercioname].totalBills = 0;
                    container.emailreceiptsbyComerce[comercioname].gastosAnotherCurrency = {};
                    container.emailreceiptsbyComerce[comercioname].gastos = [];
                    container.emailreceiptsbyComerce[comercioname].users = {};
                }
                if(!(container.emailreceiptsbyComerce[comercioname].users[gasto.UserId])){
                    container.emailreceiptsbyComerce[comercioname].users[gasto.UserId] = {total: 0, id: gasto.UserId};
                }
                container.emailreceiptsbyComerce[comercioname].users[gasto.UserId].total += gastoCost;
                container.emailreceiptsbyComerce[comercioname].receipts.push(gasto.emailReceipt)
                container.emailreceiptsbyComerce[comercioname].totalAmount += gastoCost;
                container.emailreceiptsbyComerce[comercioname].totalBills++;
            
                // Another currency
                if(isanothercurrency) {
                    this._addGastoToCurrency(container.emailreceiptsbyComerce[comercioname].gastosAnotherCurrency, gasto, gastoinanothercurrency);
                }

                container.emailreceiptsbyComerce[comercioname].gastos.push(gasto);

            } else {
                if(!(emailSourceId in container.emailreceipts)) {
                    container.emailreceipts[emailSourceId] = {};
                    container.emailreceipts[emailSourceId].receipts = [gasto.emailReceipt];   
                    container.emailreceipts[emailSourceId].isEmailReceipt = true;
                    container.emailreceipts[emailSourceId].sourceName = gasto.emailReceipt.EmailSource.name;
                    container.emailreceipts[emailSourceId].totalAmount = 0;
                    container.emailreceipts[emailSourceId].totalBills = 0;
                    container.emailreceipts[emailSourceId].gastos = [];   
                    container.emailreceipts[emailSourceId].users = {}
                }
                if(!(container.emailreceipts[emailSourceId].users[gasto.UserId])) {
                    container.emailreceipts[emailSourceId].users[gasto.UserId] = {total: 0, id: gasto.UserId};
                }
                container.emailreceipts[emailSourceId].users[gasto.UserId].total += gastoCost;
                container.emailreceipts[emailSourceId].receipts.push(gasto.emailReceipt)
                container.emailreceipts[emailSourceId].totalAmount += gastoCost;
                container.emailreceipts[emailSourceId].totalBills++;

                // Another currency
                if(isanothercurrency) {
                    this._addGastoToCurrency(container.emailreceipts[emailSourceId].gastosAnotherCurrency, gasto, gastoinanothercurrency);
                }

                container.emailreceipts[emailSourceId].gastos.push(gasto);
            }
        } else if(gasto.isManualEntry) {
            gasto.totalAmount = gasto.amount;
            gasto.totalBills = 1;
            gasto.users = {};
            gasto.users[gasto.UserId] = {total: gasto.amount, id: gasto.UserId, percentage: 1};
            container.manualEntries.push(gasto);
            container.AllGastos.push(gasto);
        }
        container.totalAmount += gastoCost;
        return container;
    }

    _roundContainer(container) {
        container.totalAmount = container.totalAmount.toFixed(2);
        Object.keys(container.businesses).forEach(businessid => {
            Object.keys(container.businesses[businessid].users).forEach(userid => {
                container.businesses[businessid].users[userid].percentage = (container.businesses[businessid].users[userid].total/container.businesses[businessid].totalAmount);
            })
            container.businesses[businessid].totalAmount = container.businesses[businessid].totalAmount.toFixed(2);
            container.AllGastos.push(container.businesses[businessid]);
        });
        Object.keys(container.vouchers).forEach(voucherterminal => {
            Object.keys(container.vouchers[voucherterminal].users).forEach(userid => {
                container.vouchers[voucherterminal].users[userid].percentage = (container.vouchers[voucherterminal].users[userid].total/container.vouchers[voucherterminal].totalAmount);
            })
            container.vouchers[voucherterminal].totalAmount = container.vouchers[voucherterminal].totalAmount.toFixed(2);
            container.AllGastos.push(container.vouchers[voucherterminal]);
        });
        Object.keys(container.emailreceipts).forEach(emailsourceid => {
            Object.keys(container.emailreceipts[emailsourceid].users).forEach(userid => {
                container.emailreceipts[emailsourceid].users[userid].percentage = (container.emailreceipts[emailsourceid].users[userid].total/container.emailreceipts[emailsourceid].totalAmount);
            });
            container.emailreceipts[emailsourceid].totalAmount = container.emailreceipts[emailsourceid].totalAmount.toFixed(2);
            container.AllGastos.push(container.emailreceipts[emailsourceid]);
        });
        Object.keys(container.emailreceiptsbyComerce).forEach(comercename => {
            Object.keys(container.emailreceiptsbyComerce[comercename].users).forEach(userid => {
                container.emailreceiptsbyComerce[comercename].users[userid].percentage = (container.emailreceiptsbyComerce[comercename].users[userid].total/container.emailreceiptsbyComerce[comercename].totalAmount);
            });
            container.emailreceiptsbyComerce[comercename].totalAmount = container.emailreceiptsbyComerce[comercename].totalAmount.toFixed(2);
            container.AllGastos.push(container.emailreceiptsbyComerce[comercename]);
        });
        container.AllGastos.sort(function (a, b){return   b.totalAmount-a.totalAmount;});
        return container;
    }

    _processGastos(gastos) {
        return new Promise((resolve, reject) => {
            // Group by Categories
            let categories = {};
            let users = {};
            // Load vouchers into gastos ids.
            gastos.forEach(gasto => {
                // check for the users
                if(!(users[gasto.UserId])) {
                    users[gasto.UserId] = gasto.User;
                }
                // Get the cost
                gasto.isManualEntry = gasto.GastoTypeId == GASTO_TYPE_MANUAL_ENTRY;
                // Check for  category
                if(gasto.GastoCategory){
                    if(!(gasto.GastoCategory.id in categories)) {
                        categories[gasto.GastoCategory.id] = this._createGastoCategoryObject(gasto.GastoCategory);
                    }
                    if(!(categories[gasto.GastoCategory.id].users[gasto.UserId])) {
                        categories[gasto.GastoCategory.id].users[gasto.UserId] = gasto.User
                    }
                    // Check for dategrouping
                    if(gasto.GastoCategory.dateGrouping) {
                        // get the date
                        let gastodate = gasto.date.split(' ')[0];
                        // Check for the date
                        if(!(categories[gasto.GastoCategory.id].gastosByDates[gastodate])) {
                            categories[gasto.GastoCategory.id].gastosByDates[gastodate] = this._createGastoDate(gastodate);
                        }
                        if(!(categories[gasto.GastoCategory.id].gastosByDates[gastodate].users[gasto.UserId])){
                            categories[gasto.GastoCategory.id].gastosByDates[gastodate].users[gasto.UserId] = gasto.User;
                        }
                        // Cehck for users
                        categories[gasto.GastoCategory.id].gastosByDates[gastodate] = this._groupGasto(gasto, categories[gasto.GastoCategory.id].gastosByDates[gastodate]);
                    } else {
                        categories[gasto.GastoCategory.id] = this._groupGasto(gasto, categories[gasto.GastoCategory.id]);
                    }
                }
            });
            // Round all values
            Object.keys(categories).forEach(categoryid => {
                if(categories[categoryid].dateGrouping){
                    let totalCategory = 0;
                    Object.keys(categories[categoryid].gastosByDates).forEach(date=> {
                        totalCategory += categories[categoryid].gastosByDates[date].totalAmount;
                        categories[categoryid].gastosByDates[date] = this._roundContainer(categories[categoryid].gastosByDates[date]);
                    });
                    categories[categoryid].totalAmount = totalCategory.toFixed(2);
                } else {
                    categories[categoryid] = this._roundContainer(categories[categoryid]);
                }
            });
            resolve({categories, users});
        });
    }

    getGastos(month, year){
       return this.serverConnection.createPromiseConnection('/gasto',{date: {month: month, year: year}}, 'POST')
       .then(result => {
            return this._processGastos(result.gastos);
       });
    }

    createManualEntry(gasto) {
        return this.serverConnection.createPromiseConnection('/gasto/manualentry', gasto, 'POST')
        .then(cb => {
            return cb.result;
        });
    }

    createFixedGasto(fixedgasto) {
        return this.serverConnection.createPromiseConnection('/gasto/fixedgasto/create', fixedgasto, 'POST')
        .then(data => {
            return data.fixedGastos;
        });
    }

    getFixedGasto() {
        return this.serverConnection.createPromiseConnection('/gasto/fixedgasto', {}, 'POST')
        .then(result => {
           return result.fixedGastos;
        });
    }

    deleteManualEntry(gasto) {
        return this.serverConnection.createPromiseConnection('/gasto/manualentry/delete', gasto, 'POST')
        .then(data=> {
            return data.result;
        });
    }

    deleteFixedGasto(gasto) {
        return this.serverConnection.createPromiseConnection('/gasto/fixedgasto/delete', gasto, 'POST')
        .then(data=> {
            return data.result;
        });
    }

    getUncategorizedGastos() {
        return this.serverConnection.createPromiseConnection('/gasto/uncategorized',{}, 'POST')
       .then(result => {
           return result.gastos;
       });
    }

    updateGastoCategory(gastos) {
        return this.serverConnection.createPromiseConnection('/gasto/uncategorized',gastos, 'PUT')
       .then(result => {
           return result.gastos;
       });
    }

    updateGastoCategoryAlone(gasto, gastoCategory) {
        return this.serverConnection.createPromiseConnection('/gasto/uncategorized/set',{gasto, gastoCategory}, 'POST')
       .then(result => {
           return result.gastos;
       });
    }
}