
import { BasicState } from "./basicstate.class";


export const INITIAL_STATES = {
    LANDING: 1,
    LOGGING: 2,
    SUBSCRIBE: 3,
    ONBOARDING: 4,
    CHANGE_ICON_CATEGORY: 5,
    UPDATE_SET_ICON: 6,
    GO_TO_MAIN: 7,
    USER_LOGGED: 8,
    TESTING: 9,
    BLOGGING: 10
  }

export class InitialState extends BasicState {
    constructor(appstore) {
        super(
            'initial',
            appstore
        );
        this.nextState = 0;
    }

    async performAction(action, parameters = null) {
        this.previousAction = action;
        switch (action) {
            case  INITIAL_STATES.TESTING:
                // this.Store.View('iconpicker').load(this.Store.iconSets['categories'], null, {selectedIcon: 'home'});
                this.Store.View('blog').load(null);
                // this.Store.View('categoryonboarding').loadOnBoarding({name: 'Javier'});
                // this.Store.View('categoryonboarding').loadOnBoarding();
                break
            case  INITIAL_STATES.LANDING:
                if (!(this.Store.loggedUser)) {
                    // Check for ireferal
                    let refmod = this.urlparameters.get('module')
                    if(refmod){

                        switch (refmod) {
                            case 'categoryshared':
                                this.Store.View('login').performLogin({isSignUp: this.urlparameters.get('isregistered')=='false', categoryshared: {name: this.urlparameters.get('categoryname'), owner: this.urlparameters.get('categoryowner')}, cleanField: true});
                                this.Store.pushHistoryState(this.createBackAction(INITIAL_STATES.LANDING), 'login');
                                
                                break;
                        
                            default:
                                this.Store.View('landing').loadLanding();
                                this.Store.pushHistoryState(this.createBackAction(), '');
                                break;
                        }

                    } else{
                        this.Store.View('landing').loadLanding();
                        this.Store.pushHistoryState(this.createBackAction(), '');
                    }
                    
                    break;
                }
            case  INITIAL_STATES.LOGGING:
                if (!(this.Store.loggedUser)) {
                    this.Store.View('login').performLogin(parameters);
                    this.Store.pushHistoryState(this.createBackAction(INITIAL_STATES.LANDING), 'login');
                    break;
                }
            case INITIAL_STATES.SUBSCRIBE:
                if (!(this.Store.loggedUser)) {
                    this.Store.View('subscription').prepareSubscription(parameters);
                    this.Store.pushHistoryState(this.createBackAction(INITIAL_STATES.LOGGING, {isSignUp:true, cleanField: false}), 'subscribe');
                    break;
                }
            break;
            case INITIAL_STATES.ONBOARDING:
                this.Store.View('categoryonboarding').loadOnBoarding(parameters);
                this.Store.pushHistoryState(this.createBackAction(INITIAL_STATES.GO_TO_MAIN, {isSignUp:true, cleanField: false}), 'newcategories');
                break;
            case INITIAL_STATES.CHANGE_ICON_CATEGORY:
                console.debug('CHANGE_ICON_CATEGORY');
                this.Store.View('iconpicker').load(parameters);
                break;
            case INITIAL_STATES.UPDATE_SET_ICON:
                console.debug('UPDATE_SET_ICON');
                this.Store.View('categoryonboarding').changeEditionIcon(parameters);
                break;
            case INITIAL_STATES.GO_TO_MAIN:
                import(/* webpackChunkName: "mainstate" */  './main.state')
                .then(({ MainState, MAIN_STATES})=> {
                    this.Store.setNewState(new MainState(this.Store), MAIN_STATES.UNCATEGORIZED);
                })
                
                break;
            case INITIAL_STATES.USER_LOGGED:
                let userlogged = parameters;
                
                import(/* webpackChunkName: "mainstate" */  './main.state')
                .then(({ MainState, MAIN_STATES})=> {
                    if(!userlogged.subscription.isValid){
                        this.Store.setNewState(new MainState(this.Store), MAIN_STATES.TRIAL_EXPIRED, userlogged.userSubscription);
                    }else if(userlogged.gastos.uncategorized.length > 0) {
                        this.Store.setNewState(new MainState(this.Store), MAIN_STATES.UNCATEGORIZED);
                    }else if(userlogged.category.invitations.length > 0) {
                        this.Store.setNewState(new MainState(this.Store), MAIN_STATES.CATEGORY_INVITATION);
                    } else {
                        this.Store.setNewState(new MainState(this.Store),MAIN_STATES.MAIN);
                    }
                });


                
        }
    }

    async backAction(backAction, parameters = null){
        switch (backAction) {
            case  INITIAL_STATES.LANDING:
                this.Store.View('landing').loadLanding();
                break;
            case  INITIAL_STATES.LOGGING:
                this.Store.View('login').performLogin(parameters);
                break;
            case INITIAL_STATES.GO_TO_MAIN:
                import(/* webpackChunkName: "mainstate" */  './main.state')
                .then(({ MainState, MAIN_STATES})=> {
                    this.Store.setNewState(new MainState(this.Store), MAIN_STATES.UNCATEGORIZED);
                });
                break;

        }
    }

    goToNextState() {
        this.performAction(this.nextState);
    }


}