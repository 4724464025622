import { BasicState } from "./basicstate.class";
import { MAIN_STATES } from "./main.state";
import { MainState } from "./main.state";

export const PUSH_STATES = {
    CATEGORY: 1,
    NEW_CATEGORY: 2,
    NEW_CATEGORY_CREATED: 3,
    NEW_CATEGORY_SHARED: 31,
    CATEGORY_DETAIL: 4,
    CATEGORY_EDITION: 5,
    CATEGORY_BUDGETING: 6,
    CATEGORY_BUDGET_ALARM: 7,
    CATEGORY_BUSINESS_MOVE: 8,
    FARMERS_MARKET: 9,
    MANUAL_ENTRY: 10,
    GO_TO_MAIN: 11,
    USER_ACTIONS: 12,
    USER_PROFILE: 13,
    USER_PROFILE_PASS: 14,
    FIXED_GASTO: 15,
    FIXED_GASTO_ADD: 16,
    
};

export class PushState extends BasicState {

    constructor(appstore) {
        super(
            'push',
            appstore
        );
        this.nextState = 0;
    }

    async performAction(action, data = null) {
        this.previousAction = action;
        let gastosCategory;
        switch (action) {
            case  PUSH_STATES.CATEGORY:
                gastosCategory = await this.Store.Service('Category').getCategories();
                this.Store.pushHistoryState(this.createBackAction(PUSH_STATES.GO_TO_MAIN), '/category');
                this.Store.View('categories').loadCategories(gastosCategory);
                break;
            case  PUSH_STATES.NEW_CATEGORY:
                this.Store.pushHistoryState(this.createBackAction(PUSH_STATES.CATEGORY, {reload: false}), '/category/new');
                this.Store.View('newcategory').load();
                break;
            case  PUSH_STATES.NEW_CATEGORY_SHARED:
                this.Store.pushHistoryState(this.createBackAction(PUSH_STATES.CATEGORY, {reload: true}), '/category/new');
                this.Store.View('categoryshared').load(data);
                break;
            case  PUSH_STATES.NEW_CATEGORY_CREATED:
                this.Store.ignoreHistoryBack();
                gastosCategory = await this.Store.Service('Category').getCategories();
                this.Store.View('categories').loadCategories(gastosCategory);
                break;
            case  PUSH_STATES.CATEGORY_DETAIL:
                // Load the general information of the module
                this.Store.pushHistoryState(this.createBackAction(PUSH_STATES.CATEGORY, {reload: false}), '/category/detail');
                this.Store.View('categorydetail').load(data);
                break;
            case  PUSH_STATES.CATEGORY_BUDGETING:
                // Load the general information of the module
                this.Store.pushHistoryState(this.createBackAction(PUSH_STATES.CATEGORY_DETAIL, {reload: false}), '/category/detail/budget');
                this.Store.View('categorybudgeting').load(data);
                break;
            case  PUSH_STATES.CATEGORY_BUDGET_ALARM:
                    // Load the general information of the module
                    this.Store.pushHistoryState(this.createBackAction(PUSH_STATES.CATEGORY_BUDGETING, {reload: false}), '/category/detail/budget/alarm');
                    this.Store.View('newbudgetalarm').load(data);
                    break;
            case  PUSH_STATES.CATEGORY_EDITION:
                this.Store.pushHistoryState(this.createBackAction(PUSH_STATES.CATEGORY_DETAIL, {reload: false}), '/category/detail/edit');
                // Load the general information of the module
                this.Store.View('categoryedition').load(data);
                break;
            case  PUSH_STATES.CATEGORY_BUSINESS_MOVE:
                this.Store.pushHistoryState(this.createBackAction(PUSH_STATES.CATEGORY_DETAIL, {reload: false}), '/category/detail/movebusiness');
                // Load the general information of the module
                this.Store.View('categorybusinessmove').load(data);
                break
            case  PUSH_STATES.MANUAL_ENTRY:
                gastosCategory = await this.Store.Service('Category').getCategories();
                this.Store.pushHistoryState(this.createBackAction(PUSH_STATES.GO_TO_MAIN), '/manualentry');
                this.Store.View('manualentry').load(gastosCategory);
                break;
            case PUSH_STATES.USER_ACTIONS:
                this.Store.pushHistoryState(this.createBackAction(PUSH_STATES.GO_TO_MAIN), '/useractions');
                this.Store.View('user').load();
                break;
            case PUSH_STATES.USER_PROFILE:
                this.Store.pushHistoryState(this.createBackAction(PUSH_STATES.USER_ACTIONS), '/userprofile');
                this.Store.View('personalinfo').load();
                break;
            case PUSH_STATES.USER_PROFILE_PASS:
                this.Store.pushHistoryState(this.createBackAction(PUSH_STATES.USER_PROFILE), '/userprofile/password');
                this.Store.View('userpassword').load();
                break;
            case PUSH_STATES.FIXED_GASTO: 
                this.Store.pushHistoryState(this.createBackAction(PUSH_STATES.USER_ACTIONS), '/fixedgasto');
                this.Store.View('fixedgasto').load();
                break;
            case PUSH_STATES.FIXED_GASTO_ADD:
                this.Store.pushHistoryState(this.createBackAction(PUSH_STATES.FIXED_GASTO), '/fixedgasto/add');
                gastosCategory = await this.Store.Service('Category').getCategories();
                this.Store.View('fixedgastoadd').load(gastosCategory);
                break
            case PUSH_STATES.FARMERS_MARKET:
                break;
        }
    }

    async backAction(backAction, parameters = null) {
        switch (backAction) {
            case PUSH_STATES.CATEGORY:
                if(parameters && parameters.reload){
                    let gastosCategory = await this.Store.Service('Category').getCategories();
                    this.Store.View('categories').loadCategories(gastosCategory);
                } else {
                    this.Store.View('categories').loadCategories(null, false);
                }
                break;
            case PUSH_STATES.CATEGORY_DETAIL:
                this.Store.View('categorydetail').loadView();
                break;
            case PUSH_STATES.CATEGORY_BUDGETING:
                this.Store.View('categorybudgeting').loadView();
                break;
            case PUSH_STATES.USER_ACTIONS:
                this.Store.View('user').load();
                break;
            case PUSH_STATES.FIXED_GASTO:
                this.Store.View('fixedgasto').load();
                break;
            case PUSH_STATES.USER_PROFILE:
                this.Store.View('personalinfo').load();
                break;
                
            case PUSH_STATES.GO_TO_MAIN:
                this.Store.setNewState(new MainState(this.Store), MAIN_STATES.UNCATEGORIZED);
                break;
        }
    }
    
    goToNextState() {
        this.performAction(this.nextState);
    }

    
}