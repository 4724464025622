import { BasicView } from "../basicview.class";
import {MDCSelect} from '@material/select';
import {MDCRipple} from '@material/ripple';
import { MAIN_STATES } from "../../store/states/main.state";
import view from './uncategorized.view.html';
import icons from './uncategorized.icons.html';

const UCG_ITEM_HTML = `<li class="mdc-list-item template-select-item" data-value="">
          <span class="mdc-list-item__ripple"></span>
          <span class="mdc-list-item__text"></span>
        </li>`;
const UCG_HTML = `<div class="category category-template">
<div class="header">
  <span class="title"></span>
  <span class="total"></span>
</div>
<div class="content-category ">
  <div class="mdc-select myselect select-width-margin  mdc-select--outlined">
    <div class="mdc-select__anchor">
      <span class="mdc-select__selected-text"></span>
      <span class="mdc-select__dropdown-icon">
        <svg
            class="mdc-select__dropdown-icon-graphic"
            viewBox="7 10 10 5">
          <polygon
              class="mdc-select__dropdown-icon-inactive"
              stroke="none"
              fill-rule="evenodd"
              points="7 10 12 15 17 10">
          </polygon>
          <polygon
              class="mdc-select__dropdown-icon-active"
              stroke="none"
              fill-rule="evenodd"
              points="7 15 12 10 17 15">
          </polygon>
        </svg>
      </span>
      <span class="mdc-floating-label">Seleccione Categoria</span>
      <span class="mdc-line-ripple"></span>
    </div>
  
    <div class="mdc-select__menu mdc-menu mdc-menu-surface mdc-menu-surface--fullwidth">
      <ul class="mdc-list">
        <li class="mdc-list-item mdc-list-item--selected" data-value="" aria-selected="true">
          <span class="mdc-list-item__ripple"></span>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="actions text-right">
  <button class="mdc-button save-button" disabled="true">
    <span class="mdc-button__ripple"></span>
    <span class="mdc-button__label">Guardar</span>
  </button>
</div>
</div>`;

export class UncategorizedModule extends BasicView {
    
    constructor() {
        super(
            'uncategorized',
            view,
            icons
        )

        // categoryService.getCategories()
        // .then(allcategories => {
        //     this.categories = allcategories;
            // let templateDom = document.querySelector('.category-template').cloneNode(true);
            // allcategories.forEach(category => {
            //     let templatecategory = document.querySelector('.template-select-item').cloneNode(true);
            
            // });
            // new MDCSelect(templateDom.querySelector('.mdc-select'));
            // this.dom.querySelector('.container').appendChild(templateDom);
        // });
        // console.log('allcategories', allcategories);
        // let selectdom = this.dom.querySelector('.mdc-select');
        // this.testselect = new MDCSelect(selectdom);
    }

    async init(){
      this.categories = [];
        this.workingGastos = [];
        //this.categories = await this.Service('Category').getCategories();
    }

    getGraph() {
        return {left: [], current: this.getViewObject(), right: ['main']};
    }

    cleanView() {
        this.cleanContainer(this.dom.querySelector('.container'));
        this.workingGastos= [];
    }

    appendToContainer(element) {
        this.dom.querySelector('.container').appendChild(element);
    }
    
    loadUncategorizedGastos(gastos, categories) {
        this.categories = [...categories.myCategories, ...categories.sharedCategories];
        this.cleanView();
        gastos.forEach(gasto => {
            // Create dom
            let newGastoDOM = this.createDOM(UCG_HTML);
            // lets fill categoryoption
            this.categories.forEach(category => {
                let categorydom = this.createDOM(UCG_ITEM_HTML);
                categorydom.dataset.value = category.id;
                categorydom.querySelector('.mdc-list-item__text').innerHTML = `<span>${category.name}</span>${category.shared ? '<svg class="icon icon-people"><use xlink:href="#icon-people"></use></svg>' : ''}`;
                if(category.shared){
                  categorydom.querySelector('.mdc-list-item__text').classList.add('with-icon');
                }
                newGastoDOM.querySelector('.mdc-list').appendChild(categorydom);
            });
            let selectdata = new MDCSelect(newGastoDOM.querySelector('.mdc-select'));
            if (gasto.emailReceipt) {
              //check for comercio
              let comercio = gasto.emailReceipt.EmailReceiptMetadata.find(metadata => metadata.key === 'Comercio');
              if(comercio) {
                newGastoDOM.querySelector('.title').textContent = comercio.value;
              } else {
                newGastoDOM.querySelector('.title').textContent = gasto.emailReceipt.EmailSource.name;
              }
            } else {
              newGastoDOM.querySelector('.title').textContent = (gasto.Business ? gasto.Business.name : (gasto.voucher ? gasto.voucher.business : 'Sin Nombre'));
            }

            if(gasto.exchangeColones) {
              newGastoDOM.querySelector('.total').innerHTML= `<i>$${gasto.amount}</i> ≈ ${(parseFloat(gasto.amount) * parseFloat(gasto.exchangeColones)).toFixed(2)}` ;
            } else {
              newGastoDOM.querySelector('.total').textContent =  gasto.amount;
            }
            
            (function (sel, gas, dom, mod){
              dom.querySelector('.save-button').addEventListener('click', function () {
                dom.parentNode.removeChild(dom);
                mod.Service('Gastos').updateGastoCategoryAlone(gas, {id: sel.value})
                .then(() => {
                  mod.updateUIAfterAction(gas, mod);
                });
              }, false);
              sel.listen('MDCSelect:change', () => {
                if(sel.value != '') {
                  dom.querySelector('.save-button').removeAttribute('disabled');
                } else {
                  dom.querySelector('.save-button').setAttribute('disabled', true);
                }
              });
            })(selectdata, gasto, newGastoDOM, this);
            
            this.workingGastos.push({dom: newGastoDOM, data: gasto, control: selectdata, processed: false});
            this.appendToContainer(newGastoDOM);
        });
        // it's moment to show the view
        this.UIManager.viewTransition('uncategorized');
    }

    updateUIAfterAction(gasto, module) {
      let dataindex = module.workingGastos.findIndex(wg => wg.data.id === gasto.id);
      if(dataindex >= 0) {
        module.workingGastos[dataindex].processed= true;
      }
      let datafalse = module.workingGastos.findIndex(wg => wg.processed === false);
      if(!(datafalse >= 0)) {
        this.Store.pushStateAction(MAIN_STATES.MAIN);
      }
    }

    updateUncategorizedGastos() {
        // Buuild the gastos
        let gastos = this.workingGastos.map(working=> {
            working.data.GastoCategoryId = working.control.value;
            return working.data;
        });
        this.Service('Gastos').updateGastoCategory(gastos)
        .then(result=> {
            this.Store.pushStateAction(MAIN_STATES.MAIN);
        }); 
    }
}