import { BasicView } from '../basicview.class';
import {MDCRipple} from '@material/ripple';
import { PUSH_STATES } from "../../store/states/push.state";
import view from './blog.view.html';
import icons from './blog.icons.html';

export class BlogModule extends BasicView {
    constructor() {
        // Call to the super class
        super(
            'blog',
        );        
        this.dom = document.querySelector('.blogview');
    }

    init() {
    }

    getGraph() {
        return {left: [], current: this.getViewObject(), right: []};
    }

    load() {
        this.UIManager.viewTransition('blog');
    }
}