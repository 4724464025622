import { BasicView } from "../basicview.class";

export class LoadingModule extends BasicView {

    constructor(){
        super(
            'loading',
        )
        this.dom = document.querySelector('.loadingview');
    }

    getGraph() {
        return {left: [], current: this.getViewObject(), right: ['landing', 'main','login', 'categorypendinginvitations', 'uncategorized', 'expiredsubscription']};
        // return {left: [], current: this.getViewObject(), right: []};
    }
}