export class BlogService {

    constructor(serverconnection) {
        this.serverConnection = serverconnection;
    }

    getBlogTop() {
        return this.serverConnection.createPromiseConnection('/blog/tops', {}, 'GET')
        .then(result => {
            let blog = result.blogs;
            return blog;
        });
    }
}